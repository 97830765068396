import autoSelectLoanOrderType from "./aircraftsOrdersNew.js";
import incomeChart from "./incomeChart.ts";
import renderBrowserNavigation from "./browserNavigation.jsx";
import renderMap from "./maps";
import renderPerformanceChart from "./performance";

import 'vite/modulepreload-polyfill';

window.autoSelectLoanOrderType = autoSelectLoanOrderType;
window.incomeChart = incomeChart;
window.renderMap = renderMap;
window.renderPerformanceChart = renderPerformanceChart;

renderBrowserNavigation();