import clsx from "clsx";
import * as d3 from "d3";
import {useMemo} from "react";
import useChartDimensions from "../d3/useChartDimensions.js";
import styles from './PerformanceChart.module.less';

const chartSettings = {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 40,
    marginLeft: 45,
};

export default function PerformanceChart({data, labels}) {
    const [ref, dimensions] = useChartDimensions(chartSettings);

    const height = dimensions.width * 0.5;

    const [domain, range] = useMemo(() => {
        let allValues = data.referenceGraphs.flat();
        if (data.missionGraph) {
            allValues = allValues.concat(data.missionGraph);
        }
        if (data.marker) {
            allValues.push(data.marker);
        }

        const xExtent = d3.extent(allValues, v => v.x);
        const yExtent = d3.extent(allValues, v => v.y);
        return [
            [xExtent[0], xExtent[1] * 1.1],
            [yExtent[0], yExtent[1] * 1.1],
        ];
    }, [data]);

    const chartSpecs = useMemo(() => {
        const xScale = d3.scaleLinear()
            .domain(domain)
            .range([0, dimensions.boundedWidth]);
        const xTicks = xScale.ticks().map(value => ({
            value,
            xOffset: xScale(value)
        }));

        const yScale = d3.scaleLinear()
            .domain(range)
            .range([dimensions.boundedHeight, 0]);
        const yTicks = yScale.ticks().map(value => ({
            value,
            yOffset: yScale(value)
        }));

        return {
            height: dimensions.boundedHeight,
            width: dimensions.boundedWidth,
            xAxis: {
                scale: xScale,
                ticks: xTicks
            },
            yAxis: {
                scale: yScale,
                ticks: yTicks
            }
        };
    }, [domain, range, dimensions.boundedWidth, dimensions.boundedHeight]);

    const areaGraph = data.referenceGraphs.length === 1 ? data.referenceGraphs[0] : data.missionGraph;

    return (
        <div className={styles.container} ref={ref}>
            <svg width={dimensions.width} height={height} className={'chart performance-chart'}>
                <g transform={`translate(${[
                    dimensions.marginLeft,
                    dimensions.marginTop
                ].join(",")})`}>
                    <XGrid ticks={chartSpecs.xAxis.ticks} height={dimensions.boundedHeight}/>
                    <YGrid ticks={chartSpecs.yAxis.ticks} width={dimensions.boundedWidth}/>

                    {areaGraph && <Area data={areaGraph} chartSpecs={chartSpecs} className={'reference-area'}/>}

                    {data.referenceGraphs.map((graph, index) => (
                        <Line key={index} data={graph} chartSpecs={chartSpecs} className={'reference-line'}/>
                    ))}

                    <Line data={data.missionGraph} chartSpecs={chartSpecs} className={'mission-line'}/>

                    {data.marker && <Marker value={data.marker} chartSpecs={chartSpecs}/>}

                    <LeftAxis {...chartSpecs.yAxis}/>
                    <g transform={`translate(${[
                        0,
                        dimensions.boundedHeight,
                    ].join(",")})`}>
                        <BottomAxis {...chartSpecs.xAxis}/>
                    </g>

                    <text x={dimensions.boundedWidth} y={dimensions.boundedHeight + 26} dy={'0.71em'}
                          className={'axis-label x-axis-label'}>
                        {labels.xAxis}
                    </text>

                    <g transform={`translate(-30, 0)`}>
                        <text transform={'rotate(270)'} className={'axis-label y-axis-label'}>
                            {labels.yAxis}
                        </text>
                    </g>
                </g>
            </svg>
        </div>
    );
}

const Line = (props) => {
    const {data, chartSpecs, className, ...rest} = props;
    const points = useMemo(() => {
        return data.map(d => {
            return chartSpecs.xAxis.scale(d.x) + ' ' + chartSpecs.yAxis.scale(d.y);
        });
    }, [data, chartSpecs]);

    return (
        <path d={'M ' + points.join(' L ')} fill={'none'} className={clsx('line', className)} {...rest}/>
    );
}

const Area = (props) => {
    const {data, chartSpecs, className, ...rest} = props;
    const points = useMemo(() => {
        const ps = data.map(d => {
            return chartSpecs.xAxis.scale(d.x) + ' ' + chartSpecs.yAxis.scale(d.y);
        });
        ps.push(chartSpecs.xAxis.scale(0) + ' ' + chartSpecs.yAxis.scale(0));

        return ps;
    }, [data, chartSpecs]);

    return (
        <path d={'M ' + points.join(' L ') + ' Z'} stroke={'none'} className={clsx('area', className)} {...rest}/>
    );
}

const Marker = ({value, chartSpecs}) => {
    const point = useMemo(() => {
        return {
            x: chartSpecs.xAxis.scale(value.x),
            y: chartSpecs.yAxis.scale(value.y)
        };
    }, [value, chartSpecs]);

    return (
        <g className={'chart-marker'}>
            <line x1={point.x} y1={chartSpecs.height} x2={point.x} y2={point.y}></line>
            <line x1={0} y1={point.y} x2={point.x} y2={point.y}></line>
            <circle cx={point.x} cy={point.y} r="4"/>
        </g>
    );
}

const BottomAxis = ({scale, ticks}) => {
    return (
        <g className={'axis bottom-axis'} fill={'none'}>
            <path
                d={[
                    "M", scale.range()[0], 6,
                    "v", -6,
                    "H", scale.range()[1],
                    "v", 6,
                ].join(" ")}
                fill="none"
                stroke="currentColor"
            />
            {ticks.map(({value, xOffset}) => (
                <g
                    key={value}
                    transform={`translate(${xOffset}, 0)`}
                    fill="none"
                    className={'tick'}
                >
                    <line
                        y2="6"
                        stroke="currentColor"
                    />
                    <text key={value} y={9} dy={'0.71em'}>
                        {value}
                    </text>
                </g>
            ))}
        </g>
    );
}

const LeftAxis = ({scale, ticks}) => {
    return (
        <g className={'axis left-axis'} fill={'none'}>
            <path
                d={[
                    "M", -6, scale.range()[0],
                    "h", 6,
                    "V", scale.range()[1],
                    "v", -6,
                ].join(" ")}
                fill="none"
                stroke="currentColor"
            />
            {ticks.map(({value, yOffset}) => (
                <g
                    key={value}
                    transform={`translate(0, ${yOffset})`}
                    fill="none"
                    className={'tick'}
                >
                    <line
                        x2="-6"
                        stroke="currentColor"
                    />
                    <text key={value} x={-9} dy={'0.32em'}>
                        {value}
                    </text>
                </g>
            ))}
        </g>
    );
}

const XGrid = ({ticks, height}) => {
    return (
        <g className={'grid grid-x'} fill={'none'}>
            {ticks.map(({value, xOffset}) => (
                <g
                    key={value}
                    transform={`translate(${xOffset}, 0)`}
                    fill="none"
                    className={'tick'}
                >
                    <line
                        y2={height}
                        stroke="currentColor"
                    />
                </g>
            ))}
        </g>
    );
}

const YGrid = ({ticks, width}) => {
    return (
        <g className={'grid grid-y'} fill={'none'}>
            {ticks.map(({value, yOffset}) => (
                <g
                    key={value}
                    transform={`translate(0, ${yOffset})`}
                    fill="none"
                    className={'tick'}
                >
                    <line
                        x2={width}
                        stroke="currentColor"
                    />
                </g>
            ))}
        </g>
    );
}