import * as d3 from 'd3';

const parseDate = d3.timeParse("%Y-%m-%d");

type RawItem = {
    date: string;
    in: number;
    out: number;
}

type ParsedItem = {
    date: Date;
    in: number;
    out: number;
}

export default function incomeChart(containerId: string, rawData: RawItem[]): void {
    const container = document.getElementById(containerId);
    if (!container) {
        return;
    }

    const data: ParsedItem[] = rawData.map(function (d) {
        return {
            date: parseDate(d.date),
            in: d.in,
            out: d.out,
        };
    });

    render(container, data);
    window.addEventListener('resize', () => {
        container.replaceChildren();
        render(container, data);
    });
}

function render(container: HTMLElement, data: ParsedItem[]): void {
    const margin = {top: 5, right: 10, bottom: 30, left: 40},
        width = container.clientWidth,
        height = container.clientHeight;
    const curve = d3.curveStep;

    // Set up scales.
    const x = d3.scaleTime()
        .domain(d3.extent(data, (d: ParsedItem) => d.date))
        .range([margin.left, width - margin.right]);

    const y = d3.scaleLinear()
        .domain([
            d3.min(data, function (d: ParsedItem) {
                return Math.min(d.in * 0.09, d.out * 0.09);
            }),
            d3.max(data, function (d: ParsedItem) {
                return Math.max(d.in * 1.01, d.out * 1.01);
            })
        ])
        .range([height - margin.bottom, margin.top]);

    // Set up axes and grid.
    const xAxis = d3.axisBottom(x)
        .tickSizeOuter(0);
    const xGrid = d3.axisBottom(x)
        .tickSizeInner(-height + margin.top + margin.bottom)
        .tickSizeOuter(0)
        .tickFormat(() => "");

    const yAxis = d3.axisLeft(y)
        .tickFormat(d3.format('.2s'));
    const yGrid = d3.axisLeft(y)
        .tickSizeInner(-width + margin.left + margin.right)
        .tickSizeOuter(0)
        .tickFormat(() => "");

    // Create SVG container.
    const svg = d3.select(container).append("svg")
        .attr("viewBox", [0, 0, width, height])
        .attr("width", width)
        .attr("height", height)
        .datum(data);

    // Render grid.
    svg.append("g")
        .attr("class", "grid grid-x")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(xGrid);
    svg.append("g")
        .attr("class", "grid grid-y")
        .attr("transform", `translate(${margin.left},0)`)
        .call(yGrid);

    // Create the clipPaths.
    svg.append("clipPath")
        .attr("id", "clip-above")
        .append("path")
        .attr("d", d3.area()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y0(0)
            .y1((d: any) => y(d.in)));

    svg.append("clipPath")
        .attr("id", "clip-below")
        .append("path")
        .attr("d", d3.area()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y0(height)
            .y1((d: any) => y(d.in)));

    // Render the color areas.
    svg.append("path")
        .attr("class", "area above")
        .attr("clip-path", "url(#clip-above)")
        .attr("d", d3.area()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y0((d: any) => y(d.out))
            .y1((d: any) => y(d.in)));

    svg.append("path")
        .attr("class", "area below")
        .attr("clip-path", "url(#clip-below)")
        .attr("d", d3.area()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y0((d: any) => y(d.out))
            .y1((d: any) => y(d.in)));

    // Render lines.
    svg.append("path")
        .attr("class", "line")
        .attr("d", d3.line()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y((d: any) => y(d.in)));

    svg.append("path")
        .attr("class", "line2")
        .attr("d", d3.line()
            .curve(curve)
            .x((d: any) => x(d.date))
            .y((d: any) => y(d.out)));

    // Render axes.
    svg.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .attr("class", "x axis")
        .call(xAxis);
    svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .attr("class", "y axis")
        .call(yAxis);
}